// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-blended-smart-learning-index-js": () => import("./../../../src/pages/about-us/blended-smart-learning/index.js" /* webpackChunkName: "component---src-pages-about-us-blended-smart-learning-index-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-about-us-our-story-index-js": () => import("./../../../src/pages/about-us/our-story/index.js" /* webpackChunkName: "component---src-pages-about-us-our-story-index-js" */),
  "component---src-pages-about-us-teaching-philosophy-index-js": () => import("./../../../src/pages/about-us/teaching-philosophy/index.js" /* webpackChunkName: "component---src-pages-about-us-teaching-philosophy-index-js" */),
  "component---src-pages-author-dolieltsdinhluc-js": () => import("./../../../src/pages/author/dolieltsdinhluc.js" /* webpackChunkName: "component---src-pages-author-dolieltsdinhluc-js" */),
  "component---src-pages-bao-chi-js": () => import("./../../../src/pages/bao-chi.js" /* webpackChunkName: "component---src-pages-bao-chi-js" */),
  "component---src-pages-chinh-sach-bao-mat-js": () => import("./../../../src/pages/chinh-sach-bao-mat.js" /* webpackChunkName: "component---src-pages-chinh-sach-bao-mat-js" */),
  "component---src-pages-culture-code-js": () => import("./../../../src/pages/culture-code.js" /* webpackChunkName: "component---src-pages-culture-code-js" */),
  "component---src-pages-dam-bao-dau-ra-js": () => import("./../../../src/pages/dam-bao-dau-ra.js" /* webpackChunkName: "component---src-pages-dam-bao-dau-ra-js" */),
  "component---src-pages-dang-ky-js": () => import("./../../../src/pages/dang-ky.js" /* webpackChunkName: "component---src-pages-dang-ky-js" */),
  "component---src-pages-dieu-khoan-su-dung-js": () => import("./../../../src/pages/dieu-khoan-su-dung.js" /* webpackChunkName: "component---src-pages-dieu-khoan-su-dung-js" */),
  "component---src-pages-dol-book-thong-nao-ielts-reading-cung-linear-js": () => import("./../../../src/pages/dol-book/thong-nao-ielts-reading-cung-linear.js" /* webpackChunkName: "component---src-pages-dol-book-thong-nao-ielts-reading-cung-linear-js" */),
  "component---src-pages-free-ielts-test-contact-us-js": () => import("./../../../src/pages/free-ielts-test/contact-us.js" /* webpackChunkName: "component---src-pages-free-ielts-test-contact-us-js" */),
  "component---src-pages-gioi-thieu-linearthinking-js": () => import("./../../../src/pages/gioi-thieu-linearthinking.js" /* webpackChunkName: "component---src-pages-gioi-thieu-linearthinking-js" */),
  "component---src-pages-hall-of-fame-js": () => import("./../../../src/pages/hall-of-fame.js" /* webpackChunkName: "component---src-pages-hall-of-fame-js" */),
  "component---src-pages-he-thong-co-so-js": () => import("./../../../src/pages/he-thong-co-so.js" /* webpackChunkName: "component---src-pages-he-thong-co-so-js" */),
  "component---src-pages-hoc-ielts-online-js": () => import("./../../../src/pages/hoc-ielts-online.js" /* webpackChunkName: "component---src-pages-hoc-ielts-online-js" */),
  "component---src-pages-ielts-cap-toc-js": () => import("./../../../src/pages/ielts-cap-toc.js" /* webpackChunkName: "component---src-pages-ielts-cap-toc-js" */),
  "component---src-pages-ielts-chuoi-ung-dung-index-js": () => import("./../../../src/pages/ielts/chuoi-ung-dung/index.js" /* webpackChunkName: "component---src-pages-ielts-chuoi-ung-dung-index-js" */),
  "component---src-pages-ielts-co-ban-js": () => import("./../../../src/pages/ielts-co-ban.js" /* webpackChunkName: "component---src-pages-ielts-co-ban-js" */),
  "component---src-pages-ielts-nang-cao-js": () => import("./../../../src/pages/ielts-nang-cao.js" /* webpackChunkName: "component---src-pages-ielts-nang-cao-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-khoa-hoc-ielts-js": () => import("./../../../src/pages/khoa-hoc-ielts.js" /* webpackChunkName: "component---src-pages-khoa-hoc-ielts-js" */),
  "component---src-pages-khoa-hoc-khac-js": () => import("./../../../src/pages/khoa-hoc-khac.js" /* webpackChunkName: "component---src-pages-khoa-hoc-khac-js" */),
  "component---src-pages-lien-he-js": () => import("./../../../src/pages/lien-he.js" /* webpackChunkName: "component---src-pages-lien-he-js" */),
  "component---src-pages-linearteachers-js": () => import("./../../../src/pages/linearteachers.js" /* webpackChunkName: "component---src-pages-linearteachers-js" */),
  "component---src-pages-lp-linear-workshop-js": () => import("./../../../src/pages/lp-linear-workshop.js" /* webpackChunkName: "component---src-pages-lp-linear-workshop-js" */),
  "component---src-pages-luyen-thi-ielts-tu-duy-js": () => import("./../../../src/pages/luyen-thi-ielts-tu-duy.js" /* webpackChunkName: "component---src-pages-luyen-thi-ielts-tu-duy-js" */),
  "component---src-pages-luyen-thi-sat-tu-duy-js": () => import("./../../../src/pages/luyen-thi-sat-tu-duy.js" /* webpackChunkName: "component---src-pages-luyen-thi-sat-tu-duy-js" */),
  "component---src-pages-nen-tang-cong-nghe-js": () => import("./../../../src/pages/nen-tang-cong-nghe.js" /* webpackChunkName: "component---src-pages-nen-tang-cong-nghe-js" */),
  "component---src-pages-nghe-1-js": () => import("./../../../src/pages/nghe1.js" /* webpackChunkName: "component---src-pages-nghe-1-js" */),
  "component---src-pages-online-index-js": () => import("./../../../src/pages/online/index.js" /* webpackChunkName: "component---src-pages-online-index-js" */),
  "component---src-pages-online-step-1-js": () => import("./../../../src/pages/online/step1.js" /* webpackChunkName: "component---src-pages-online-step-1-js" */),
  "component---src-pages-online-step-2-js": () => import("./../../../src/pages/online/step2.js" /* webpackChunkName: "component---src-pages-online-step-2-js" */),
  "component---src-pages-online-step-3-js": () => import("./../../../src/pages/online/step3.js" /* webpackChunkName: "component---src-pages-online-step-3-js" */),
  "component---src-pages-online-step-4-js": () => import("./../../../src/pages/online/step4.js" /* webpackChunkName: "component---src-pages-online-step-4-js" */),
  "component---src-pages-online-step-5-js": () => import("./../../../src/pages/online/step5.js" /* webpackChunkName: "component---src-pages-online-step-5-js" */),
  "component---src-pages-online-step-6-js": () => import("./../../../src/pages/online/step6.js" /* webpackChunkName: "component---src-pages-online-step-6-js" */),
  "component---src-pages-our-story-index-js": () => import("./../../../src/pages/our-story/index.js" /* webpackChunkName: "component---src-pages-our-story-index-js" */),
  "component---src-pages-sat-js": () => import("./../../../src/pages/sat.js" /* webpackChunkName: "component---src-pages-sat-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-test-entrance-1-js": () => import("./../../../src/pages/test-entrance1.js" /* webpackChunkName: "component---src-pages-test-entrance-1-js" */),
  "component---src-pages-test-entrance-2-js": () => import("./../../../src/pages/test-entrance2.js" /* webpackChunkName: "component---src-pages-test-entrance-2-js" */),
  "component---src-pages-test-final-js": () => import("./../../../src/pages/test-final.js" /* webpackChunkName: "component---src-pages-test-final-js" */),
  "component---src-pages-test-mock-js": () => import("./../../../src/pages/test-mock.js" /* webpackChunkName: "component---src-pages-test-mock-js" */),
  "component---src-pages-thank-you-feedback-js": () => import("./../../../src/pages/thank-you-feedback.js" /* webpackChunkName: "component---src-pages-thank-you-feedback-js" */),
  "component---src-pages-thank-you-js": () => import("./../../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-pages-thong-nao-ielts-reading-cung-linear-js": () => import("./../../../src/pages/thong-nao-ielts-reading-cung-linear.js" /* webpackChunkName: "component---src-pages-thong-nao-ielts-reading-cung-linear-js" */),
  "component---src-pages-tieng-anh-giao-tiep-js": () => import("./../../../src/pages/tieng-anh-giao-tiep.js" /* webpackChunkName: "component---src-pages-tieng-anh-giao-tiep-js" */),
  "component---src-pages-tin-tuc-ve-dol-js": () => import("./../../../src/pages/tin-tuc-ve-dol.js" /* webpackChunkName: "component---src-pages-tin-tuc-ve-dol-js" */),
  "component---src-pages-toeic-js": () => import("./../../../src/pages/toeic.js" /* webpackChunkName: "component---src-pages-toeic-js" */),
  "component---src-pages-tuyen-dung-js": () => import("./../../../src/pages/tuyen-dung.js" /* webpackChunkName: "component---src-pages-tuyen-dung-js" */),
  "component---src-pages-welcome-dolbies-js": () => import("./../../../src/pages/welcome-dolbies.js" /* webpackChunkName: "component---src-pages-welcome-dolbies-js" */),
  "component---src-pages-welcome-js": () => import("./../../../src/pages/welcome.js" /* webpackChunkName: "component---src-pages-welcome-js" */),
  "component---src-pages-workshop-index-js": () => import("./../../../src/pages/workshop/index.js" /* webpackChunkName: "component---src-pages-workshop-index-js" */),
  "component---src-templates-course-landing-2021-detail-template-js": () => import("./../../../src/templates/CourseLanding2021DetailTemplate.js" /* webpackChunkName: "component---src-templates-course-landing-2021-detail-template-js" */),
  "component---src-templates-job-detail-js": () => import("./../../../src/templates/JobDetail.js" /* webpackChunkName: "component---src-templates-job-detail-js" */),
  "component---src-templates-teacher-detail-template-js": () => import("./../../../src/templates/TeacherDetailTemplate.js" /* webpackChunkName: "component---src-templates-teacher-detail-template-js" */)
}

