import React from 'react';
import { Provider } from 'react-redux';

import store from './src/state/createStore';
import { QueryClient, QueryClientProvider } from 'react-query';

// eslint-disable-next-line react/prop-types
const Wrap = ({ element }) => {
  const queryClient = new QueryClient();
  // Instantiating store in `wrapRootElement` handler ensures:
  //  - there is fresh store for each SSR page
  //  - it will be called only once in browser, when React mounts
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>{element}</Provider>
    </QueryClientProvider>
  );
};

export default Wrap;
