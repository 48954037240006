import {
  FT_ANSWER,
  FT_NEXT_QUESTION,
  FT_PREV_QUESTION,
  FT_SELECT_QUESTION,
  FT_SELECT_SECTION,
  FT_SET_TEST_CONTENT,
  FT_START_TEST,
  FT_STOP_TEST,
  FT_TIME_OUT
} from './types';
import { doMarking, preprocess, transformTFN } from './utils';

const initialDoingTestState = {
  /**
   * Indicate beyond time limit but student still not complete his/her test
   * -> inform them, then navigate to evaluation page
   */
  isTimeout: false,

  /**
   * Indicates which section student doing test
   */
  currentSectionIdx: 0,
  /**
   * Indicates which question student doing test
   */
  currentQuestionIdx: 0,
  /**
   * User's answers
   */
  answers: {},
  /**
   * Final result, array of questions with answers & correctness
   */
  results: []
};

const initialState = {
  loaded: false,
  isTestStarted: false,
  running: false,
  /**
   * For counting question on specific section.
   * Ex: section 1 have 5 question, section 2 have 4 question
   * then our object will be {0: 5, 1: 9}. We're in question number
   * 2 (2nd question) of section 2 -> then the current progress
   * is 5 + 2  = 7
   *
   */
  questionProgress: {},
  /**
   * Count all questions in all sections
   */
  totalQuestions: 0,
  ...initialDoingTestState
};

export default function (state = initialState, action) {
  const { currentQuestionIdx, currentSectionIdx, sections, answers } = state;

  switch (action.type) {
    case FT_START_TEST:
      return {
        ...state,
        ...initialDoingTestState,
        running: true,
        isTestStarted: true
      };
    case FT_STOP_TEST:
      return {
        ...state,
        running: false,
        results: doMarking(sections, answers)
      };
    case FT_SET_TEST_CONTENT: {
      const transformedSections = transformTFN(action.payload.sections);
      const { totalQuestions, questionProgress } =
        preprocess(transformedSections);

      return {
        ...action.payload,
        sections: transformedSections,
        totalQuestions,
        questionProgress,
        running: true,
        loaded: true,
        isTimeout: false,
        isTestStarted: false
      };
    }
    case FT_SELECT_SECTION:
      return {
        ...state,
        currentSectionIdx: action.payload.idx,
        currentQuestionIdx: 0
      };
    case FT_SELECT_QUESTION:
      return {
        ...state,
        currentQuestionIdx: action.payload.idx
      };
    case FT_ANSWER: {
      const key =
        action.payload.position || `${currentSectionIdx}${currentQuestionIdx}`;
      return {
        ...state,
        answers: {
          ...state.answers,
          [key]: action.payload.answer
        }
      };
    }
    case FT_NEXT_QUESTION: {
      const section = sections[currentSectionIdx];
      if (currentQuestionIdx < section.questions.length - 1) {
        return { ...state, currentQuestionIdx: currentQuestionIdx + 1 };
      }

      if (currentSectionIdx < sections.length - 1) {
        return {
          ...state,
          currentSectionIdx: currentSectionIdx + 1,
          currentQuestionIdx: 0
        };
      }

      return state;
    }

    case FT_PREV_QUESTION: {
      if (currentQuestionIdx > 0) {
        return { ...state, currentQuestionIdx: currentQuestionIdx - 1 };
      }

      if (currentSectionIdx > 0) {
        const prevSectionIdx = currentSectionIdx - 1;
        const prevSectionQuestions = sections[prevSectionIdx].questions;
        return {
          ...state,
          currentSectionIdx: prevSectionIdx,
          currentQuestionIdx: prevSectionQuestions.length - 1
        };
      }

      return state;
    }

    case FT_TIME_OUT: {
      return { ...state, isTimeout: true };
    }

    default:
      return state;
  }
}
