export const LMS = `${process.env.GATSBY_LMS_API_URL}/api`;

export const HUBSPOT = {
  API_KEY: process.env.HUBSPOT_API_KEY,
  PORTAL_ID: process.env.HUBSPOT_PORTAL_ID,
  FORM_ID: process.env.HUBSPOT_FORM_ID
};

export const GATSBY_ACTIVE_ENV = `${process.env.GATSBY_ACTIVE_ENV}`;
export const GATSBY_BUILD_DATE = `${process.env.GATSBY_BUILD_DATE}`;
export const GATSBY_GRAPH_CMS_URI = `${process.env.GATSBY_GRAPH_CMS_URI}`;
export const GATSBY_GRAPH_CMS_AUTHORIZATION = `${process.env.GATSBY_GRAPH_CMS_AUTHORIZATION}`;
export const SITE_URL = `${process.env.SITE_URL}`;
export const TEXT_TO_SPEECH_URL = `${process.env.TEXT_TO_SPEECH_URL}`;
export const APPLY_JOB_URL = `${process.env.APPLY_JOB_URL}`;
export const STUDENT_REGISTRATION_URL = `${process.env.STUDENT_REGISTRATION_URL}`;

export const GATSBY_GIT_SHA = `${process.env.GATSBY_GIT_SHA}`;

export const GO_USER_SERVICE = `${process.env.GO_USER_SERVICE}`;
