const tags = 'span b br strong em i a'.split(' ');

export const normalizedText = text => {
  if (!text) return '';
  let result = text
    .replace(/<(\/)?code>/gi, '')
    .replace(/&Tab;/gi, '')
    .replace(/&NewLine;/gi, '<br>')
    .replace(/&excl;/gi, '!')
    .replace(/&quot;/gi, '"')
    .replace(/&num;/gi, '#')
    .replace(/&dollar;/gi, '$')
    .replace(/&percnt;/gi, '%')
    .replace(/&amp;/gi, '&')
    .replace(/&apos;/gi, `'`)
    .replace(/&lpar;/gi, '(')
    .replace(/&rpar;/gi, ')')
    .replace(/&ast;/gi, '*')
    .replace(/&plus;/gi, '+')
    .replace(/&comma;/gi, ',')
    .replace(/&period;/gi, '.')
    .replace(/&sol;/gi, '/')
    .replace(/&colon;/gi, ':')
    .replace(/&semi;/gi, ';')
    .replace(/&lt;/gi, '<')
    .replace(/&equals;/gi, '=')
    .replace(/&gt;/gi, '>')
    .replace(/&quest;/gi, '?')
    .replace(/&commat;/gi, '@')
    .replace(/&lsqb;/gi, '[')
    .replace(/&bsol;/gi, `\\`)
    .replace(/&rsqb;/gi, ']')
    .replace(/&Hat;/gi, '^')
    .replace(/&lowbar;/gi, '_')
    .replace(/&grave;/gi, '`')
    .replace(/&DiacriticalGrave;/gi, '`')
    .replace(/&lcub; &lbrace;/gi, '{')
    .replace(/&verbar;/gi, '|')
    .replace(/&VerticalLine;/gi, '|')
    .replace(/&vert;/gi, '|')
    .replace(/&rcub;/gi, '}')
    .replace(/&rbrace;/gi, '}')
    .replace(/&nbsp;/gi, ' ')
    .replace(/&NonBreakingSpace;/gi, ' ');

  tags.forEach(tag => {
    const startTag = `&#x3C;${tag}`;
    const endTag = `&#x3C;/${tag}>`;
    result = result
      .replace(new RegExp(startTag, 'g'), `<${tag}`)
      .replace(new RegExp(endTag, 'g'), `</${tag}>`);
  });
  return result;
};
