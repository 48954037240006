import { createSelector, createSlice } from '@reduxjs/toolkit';

import { DEFAULT_SENTENCE } from 'constant/learning-offline-trial';

const { reducer, actions } = createSlice({
  name: 'sentences',
  reducers: {
    sentenceMade(state, action) {
      const { sentence, lastMessage } = action.payload;
      state.sentenceMaker.sentences.push(sentence);
      if (lastMessage) {
        state.sentenceMaker.lastMessage = lastMessage;
      }
    },
    reset(state) {
      state.sentenceMaker.sentences = [DEFAULT_SENTENCE];
      state.sentenceMaker.lastMessage = null;
    }
  }
});

export const selectSentences = createSelector(
  state => state.learningOfflineTrial.sentenceMaker.sentences,
  s => s
);

export const selectLastMessage = createSelector(
  state => state.learningOfflineTrial.sentenceMaker.lastMessage,
  s => s
);

export default reducer;
export const { sentenceMade, reset: resetSentenceMaker } = actions;
