import { combineReducers } from 'redux';

import entranceTest from 'state/entrance-test';
import freeTest from 'state/free-test';
import learningOfflineTrial from 'state/learning-offline-trial';

export default combineReducers({
  freeTest,
  learningOfflineTrial,
  entranceTest
});
