/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import { UserTracking } from './src/utils/userTracking';
import wrapWithProvider from './wrap-with-provider';
export const wrapRootElement = wrapWithProvider;
export const onRouteUpdate = () => {
  UserTracking.trackNavigation(window.location.href);
};

export const shouldUpdateScroll = () => {
  window.scrollTo(0, 0);
  return false;
};
