module.exports = {
  IELTS_LIBRARY: {
    url: '/ielts-library',
    BLOGS: {
      url: '/ielts-library/blogs'
    }
  },
  IELTS: {
    url: 'ielts'
  }
};
