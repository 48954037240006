import {
  createEntityAdapter,
  createSelector,
  createSlice,
  nanoid
} from '@reduxjs/toolkit';
import merge from 'lodash/merge';

import { messageTypes, sender } from 'constant/learning-offline-trial';

const defaultMessageProperty = {
  sender: sender.TEACHER,
  type: messageTypes.TEXT_NORMAL,
  displayed: false
};

let waitTime = 0;

export function mergeWithDefaultValue(msg, idx) {
  let delay = msg.delay;

  // reset
  if (idx === 0 || typeof idx === 'undefined') {
    waitTime = 0;

    if (msg.wait) {
      waitTime = msg.wait;
    }
  }

  if (!delay) {
    delay = 800;
  }

  const merged = merge(
    { id: nanoid(), delay, wait: waitTime },
    defaultMessageProperty,
    msg
  );

  if (merged.type === messageTypes.TEXT_STUDENT_ENTER) {
    merged.wait = 0;
  }

  waitTime += delay;

  return merged;
}

function initScenarios(scenarios) {
  scenarios = scenarios.map(scenario => {
    scenario.messages = scenario.messages.map(mergeWithDefaultValue);
    return scenario;
  });

  return scenarios;
}

const scenarioAdapter = createEntityAdapter();
const selectors = scenarioAdapter.getSelectors(
  store => store.learningOfflineTrial.scenarios
);

export const scenarioInitalState = scenarioAdapter.getInitialState({
  currentScenarioId: null,
  status: 'idle',
  fetchedScenarios: []
});

const { reducer, actions } = createSlice({
  name: 'scenarios',
  reducers: {
    dataReceived(state, action) {
      scenarioAdapter.upsertMany(
        state.scenarios,
        initScenarios(action.payload)
      );
      state.scenarios.status = 'loaded';
    },
    fetchScenario(state, action) {
      const scenarioId = action.payload;
      state.scenarios.currentScenarioId = scenarioId;
      state.scenarios.fetchedScenarios.push(scenarioId);
    }
  }
});

export const { fetchScenario, dataReceived } = actions;
export const { selectById: selectScenarioById, selectAll: selectAllScenarios } =
  selectors;

const selectDerived = state => state.learningOfflineTrial.scenarios;

export const selectFetchedSenarios = createSelector(
  selectDerived,
  state => state.fetchedScenarios
);

export const selectCurrentSenarioId = createSelector(
  selectDerived,
  state => state.currentScenarioId
);

export const selectScenarioStatus = createSelector(
  selectDerived,
  state => state.status
);

export default reducer;
