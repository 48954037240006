module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"dolenglish.vn-website","short_name":"dolenglish","start_url":"/","background_color":"#D22222","theme_color":"#D22222","display":"minimal-ui","icon":"src/images/dol-english-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"19a827f5b25c0cd7966306e54f8be653"},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"7195447","productionOnly":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"3037407759830907"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W2L6RPB","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.dolenglish.vn","noTrailingSlash":false,"noHash":true,"noQueryString":true,"exclude":["/404"]},
    },{
      plugin: require('../plugins/gatsby-plugin-react-helmet-og-url/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.dolenglish.vn","noTrailingSlash":true,"noHash":true,"noQueryString":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
