import { flattenDeep } from 'lodash';

import { ftQuestionTypes, ftTfn } from 'constant';
import { normalizedText } from 'utils/htmlcontent.util';

/**
 * @param {} sections
 */
export const preprocess = sections => {
  let totalQuestions = 0;
  const questionProgress = {};

  if (sections && sections.length > 0) {
    for (let i = 0; i < sections.length; i++) {
      const { questions } = sections[i];
      questionProgress[i] = 0;
      if (i > 0) {
        questionProgress[i] += questionProgress[i - 1];
      }
      for (let j = 0; j < questions.length; j++) {
        const { questionRange, subQuestions } = questions[j];
        if (subQuestions && subQuestions.length) {
          for (let k = 0; k < subQuestions.length; k++) {
            questionProgress[i] += subQuestions[k].questionRange;
            totalQuestions += subQuestions[k].questionRange;
          }
        } else {
          questionProgress[i] += questionRange;
          totalQuestions += questionRange;
        }
      }
    }
  }

  return { totalQuestions, questionProgress };
};

const tfnMap = {
  t: ftTfn.TRUE,
  f: ftTfn.FALSE,
  n: ftTfn.NOT_GIVEN
};

export const transformTFN = sections =>
  sections.map(({ questions, ...section }) => ({
    ...section,
    questions: questions.map(({ subQuestions, ...question }) => ({
      ...question,
      subQuestions: subQuestions.map(subQuestion => {
        if (ftQuestionTypes.TFN === subQuestion.type)
          return {
            ...subQuestion,
            ftAnswers: subQuestion.ftAnswers.map(
              ({ answerContent, ...ftAnswer }) => ({
                ...ftAnswer,
                answerContent: tfnMap[answerContent.toLowerCase()]
              })
            )
          };

        return subQuestion;
      })
    }))
  }));

const removeAllTagMarkup = text => {
  return text.replace(/<[^>]*>?/gm, '');
};

const BR_TAG = '<br />';

const getFullQuestionAndAnswer = (question, answerStr) => {
  if (!answerStr) return question;

  const normalizedQuestion = normalizedText(question);
  let questionAndAnswer = question;

  if (normalizedQuestion.includes(BR_TAG)) {
    const fragments = normalizedQuestion.split(BR_TAG);
    fragments.shift(); // Support Vietnamese part at the beginning, separated by <br /> tag
    questionAndAnswer = fragments.join('');
  }

  const answers = answerStr.split('-');
  const hasFillIn = questionAndAnswer.indexOf('___') !== -1;
  for (const answer of answers) {
    if (hasFillIn) {
      questionAndAnswer = questionAndAnswer.replace('___', ` ${answer} `);
    } else {
      questionAndAnswer = `${questionAndAnswer}.\n${answer}`;
    }
  }
  return questionAndAnswer.replace(/=>/gm, ',');
};

export const doMarking = (sections, userAnswers) =>
  flattenDeep(
    sections.map((section, sectionIdx) =>
      section.questions.map((question, questionIdx) => {
        if (question.type === ftQuestionTypes.FILL_IN_THE_BLANK) {
          return doMarkingFIB({
            sectionIdx,
            questionIdx,
            userAnswers,
            question,
            section
          });
        }

        if (question.subQuestions && question.subQuestions.length)
          return doMarkingSubQuestions({
            sectionIdx,
            questionIdx,
            userAnswers,
            question,
            section
          });

        return doMarkingSelection({
          sectionIdx,
          questionIdx,
          userAnswers,
          question,
          section
        });
      })
    )
  );

function doMarkingFIB({
  sectionIdx,
  questionIdx,
  userAnswers,
  question,
  section
}) {
  const fibAnswers = userAnswers[`${sectionIdx}${questionIdx}`] || [];
  return question.ftAnswers.map((answer, answerIdx) => {
    const {
      questionFragmentContent,
      answerContent,
      transcript,
      audioFragment
    } = answer;

    const userAnswer = fibAnswers[answerIdx];

    return {
      userAnswer,
      isCorrect: isCorrect(userAnswer, answerContent),

      sectionId: section.id,
      questionId: question.id,
      sectionIdx,
      questionIdx: answerIdx,

      audioFragment,
      correctAnswer: answerContent,
      transcript,
      questionContent: questionFragmentContent,
      type: question.type,
      ...getGeneralAnswerInfo(answer)
    };
  });
}

function doMarkingSelection({
  sectionIdx,
  questionIdx,
  userAnswers,
  question,
  section
}) {
  const userAnswer = userAnswers[`${sectionIdx}${questionIdx}`];

  const {
    options,
    questionContent,
    type,
    ftAnswers: [answer]
  } = question;

  const { answerContent } = answer;

  return {
    userAnswer,
    isCorrect: isCorrect(userAnswer, answerContent),

    sectionId: section.id,
    sectionIdx,
    questionId: question.id,
    questionIdx,

    options,
    correctAnswer: answerContent,
    questionContent,
    questionAndAnswer: getFullQuestionAndAnswer(
      removeAllTagMarkup(questionContent.html),
      answerContent
    ),
    type,
    ...getGeneralAnswerInfo(answer)
  };
}

function doMarkingSubQuestions({
  sectionIdx,
  questionIdx,
  userAnswers,
  question,
  section
}) {
  const position = `${sectionIdx}${questionIdx}`;
  const { subQuestions } = question;

  return subQuestions.map((subQuestion, idx) => {
    const userAnswer = userAnswers[`${position}${idx}`] || '';

    const { ftAnswers, options, questionContent, type } = subQuestion;

    return ftAnswers.map((ftAnswer, answerIdx) => {
      const { answerContent, audioFragment, transcript } = ftAnswer;

      let qContent = questionContent;

      if (ftQuestionTypes.TFN === type) {
        const { questionFragmentContent } = ftAnswers[0];
        if (
          questionFragmentContent &&
          questionFragmentContent.text &&
          questionFragmentContent.text.length
        ) {
          qContent = questionFragmentContent;
        }
      }

      const hasCorrectAnswer =
        !!userAnswer && userAnswer.includes(answerContent);
      const answer = hasCorrectAnswer ? answerContent : userAnswer[answerIdx];

      return {
        userAnswer: (answer || '').replace(/_/g, ' '),
        isCorrect: hasCorrectAnswer,

        sectionId: section.id,
        sectionIdx,
        questionId: subQuestion.id,
        questionIdx,

        transcript,
        audioFragment,
        options,
        questionContent: qContent,
        fullQuestionContent: questionContent,
        type,
        correctAnswer: answerContent.replace(/_/g, ' '),
        questionAndAnswer: getFullQuestionAndAnswer(
          removeAllTagMarkup(questionContent.html),
          answerContent
        ),
        ...getGeneralAnswerInfo(ftAnswer)
      };
    });
  });
}

function getGeneralAnswerInfo(answer) {
  const {
    audioExplanation,
    videoExplanation,
    textExplanation,
    textExplanationExtend,
    imageExplanation,
    videoPoster
  } = answer;
  return {
    audioExplanation,
    videoExplanation,
    textExplanation,
    textExplanationExtend,
    imageExplanation,
    videoPoster
  };
}

export function isCorrect(userAnswer, correctAnswer) {
  if (!userAnswer || !correctAnswer) return false;

  if (correctAnswer.includes('/')) {
    return correctAnswer.split('/').some(answer => isEqual(answer, userAnswer));
  }

  return isEqual(userAnswer, correctAnswer);
}

function isEqual(str1, str2) {
  return str1.trim().toLowerCase() === str2.trim().toLowerCase();
}
