import reducerReducers from 'reduce-reducers';

import { DEFAULT_SENTENCE } from 'constant/learning-offline-trial';

import inputMetadataReducer from './input-metadata-slice';
import messagesReducer from './message-slice';
import scenariosReducers, { scenarioInitalState } from './scenario-slice';
import sentencesReducer from './sentence-maker-slice';
import studentMessageReducer from './student-message-slice';

const initalState = {
  messages: [],
  studentMessages: [],
  scenarios: scenarioInitalState,
  inputMetadata: null,
  skipVideo: false,
  sentenceMaker: {
    sentences: [DEFAULT_SENTENCE],
    // when show repeated orginal message -> there's no condition -> can't go on
    // need to save the last message has a condition -> can go on
    lastMessage: null
  }
};

export default reducerReducers(
  initalState,
  messagesReducer,
  scenariosReducers,
  studentMessageReducer,
  inputMetadataReducer,
  sentencesReducer
);
