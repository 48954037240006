import { createSlice } from '@reduxjs/toolkit';

import { fetchScenario } from './scenario-slice';
import { addStudentMessage } from './student-message-slice';

const { reducer, actions } = createSlice({
  name: 'inputMetadata',
  reducers: {
    setInputMetadata(state, action) {
      state.inputMetadata = action.payload;
    },
    hideInput(state) {
      if (state.inputMetadata) state.inputMetadata = null;
    }
  },
  extraReducers: builder => {
    builder.addCase(addStudentMessage, state => {
      state.inputMetadata.allowInputs--;
    });
    builder.addCase(fetchScenario, (state, action) => {
      if (!state.inputMetadata) return;

      const scenarioId = action.payload;

      if (scenarioId === state.inputMetadata.condition.enterIdea) {
        state.inputMetadata = null;
      }
    });
  }
});

export const selectInputMetadata = state =>
  state.learningOfflineTrial.inputMetadata;

export const selectIsInputPresented = state => {
  const { inputMetadata } = state.learningOfflineTrial;

  return inputMetadata && !!inputMetadata.allowInputs;
};

export default reducer;

export const { setInputMetadata, hideInput, fetchEnteredInputScenario } =
  actions;
