export const messageTypes = {
  TEXT_BOLD: 'TEXT_BOLD',
  ACTION_CLICK: 'ACTION_CLICK',
  ACTION_CLEAR_STUDENT_MESSAGES: 'ACTION_CLEAR_STUDENT_MESSAGES',
  ACTION_SHOW_INPUT: 'ACTION_SHOW_INPUT',
  ACTION_LOAD_SCENARIO: 'ACTION_LOAD_SCENARIO',
  ACTION_CLEAR_MESSAGES_ENTERED_BY_STUDENT:
    'ACTION_CLEAR_MESSAGES_ENTERED_BY_STUDENT',
  MEDIA_IMAGE_BANNER: 'MEDIA_IMAGE_BANNER',
  MEDIA_VIDEO: 'MEDIA_VIDEO',
  ACTION_SKIP_VIDEO: 'ACTION_SKIP_VIDEO',
  ACTION_CLEAR_STUDENT_MESSAGES_SOFT: 'ACTION_CLEAR_STUDENT_MESSAGES_SOFT',
  TEXT_STUDENT_ENTER: 'TEXT_STUDENT_ENTER',
  TEXT_NORMAL: 'TEXT_NORMAL',
  TEXT_WARNING: 'TEXT_WARNING',
  TEXT_SUCCESS: 'TEXT_SUCCESS',
  SENTENCE_MAKER: 'SENTENCE_MAKER',
  ACTION_SEND_MESSAGE: 'ACTION_SEND_MESSAGE'
};

export const NON_DISPLAY_ABLE_MESSAGES = [
  'ACTION_LOAD_SCENARIO',
  'ACTION_CLEAR_STUDENT_MESSAGES',
  'ACTION_CLEAR_STUDENT_MESSAGES_SOFT',
  'ACTION_CLEAR_MESSAGES_ENTERED_BY_STUDENT'
];

export const SELECT_SUBJECT_SENARIO_IDS = [
  'intermediate/sample-attempt-2--written-communication.json',
  'intermediate/sample-attempt-2--the-power-of-written-communication.json',
  'intermediate/sample-attempt-2--the-power-of-spoken-communication.json',
  'intermediate/sample-attempt-2--it.json'
];

export const SELECT_PARAPRAHSE_IDS_1 = [
  'intermediate/sample-attempt-1-synonyms.json',
  'intermediate/sample-attempt-1-active-to-passive.json'
];

export const SELECT_PARAPRAHSE_IDS_2 = [
  'intermediate/sample-attempt-3--written-communication.json',
  'intermediate/sample-attempt-3-is-less-powerful.json',
  'intermediate/sample-attempt-3-spoken-communication.json'
];

export const END_SCENARIO_IDS = ['basic/done.json', 'intermediate/done.json'];

export const START_SENARIO_IDS = [
  'basic/index.json',
  'intermediate/index.json',
  'basic/sample-attempt-1.json',
  'intermediate/sample-attempt-1.json'
];

export const sender = {
  TEACHER: 'TEACHER',
  STUDENT: 'STUDENT'
};

export const INIT_SCENARIO = 'index.json';

export const DEFAULT_SENTENCE =
  'Written communication is less powerful than spoken communication';
