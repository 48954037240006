import {
  createEntityAdapter,
  createSelector,
  createSlice,
  nanoid
} from '@reduxjs/toolkit';

import {
  INIT_SCENARIO,
  messageTypes,
  sender
} from 'constant/learning-offline-trial';

import { fetchScenario, mergeWithDefaultValue } from './scenario-slice';
import { sentenceMade } from './sentence-maker-slice';
import {
  addStudentMessage,
  clearMessagesEnteredByStudent
} from './student-message-slice';

export const messageAdapter = createEntityAdapter('messages');

const { reducer, actions } = createSlice({
  name: 'messages',
  reducers: {
    markDisplayed(state, action) {
      const message = state.messages.find(msg => msg.id === action.payload);
      if (message) message.displayed = true;
    },
    continueWithDifferentLevel(state) {
      state.messages = state.messages
        .filter(msg => msg.sender === 'teacher')
        .filter(msg => msg.remain);

      if (state.messages.length === 0) {
        // get 2 buttons from init scenario
        state.messages = state.scenarios.entities[INIT_SCENARIO].messages
          .filter(msg => messageTypes.ACTION_CLICK === msg.type)
          .map(msg => {
            msg.delay = 0;
            msg.wait = 0;
            return msg;
          });
      }

      // Can't go to the scenario slice -> circle reference
      state.scenarios.fetchedScenarios = [];
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchScenario, (state, action) => {
      const scenarioId = action.payload;
      const scenario = state.scenarios.entities[scenarioId];
      let { messages } = scenario;

      messages = messages.map(msg => ({ ...msg, id: nanoid() }));
      state.messages.push(...messages);
    });

    builder.addCase(addStudentMessage, (state, action) => {
      const msg = mergeWithDefaultValue({
        content: action.payload,
        type: messageTypes.TEXT_STUDENT_ENTER,
        sender: sender.STUDENT
      });

      state.messages.push(msg);
    });

    builder.addCase(clearMessagesEnteredByStudent, state => {
      state.messages = state.messages.filter(
        msg => msg.type !== messageTypes.TEXT_STUDENT_ENTER
      );
    });

    builder.addCase(sentenceMade, (state, action) => {
      const { sentence } = action.payload;

      const studentMessage = mergeWithDefaultValue({
        content: action.payload.sentence,
        type: state.sentenceMaker.sentences.includes(sentence)
          ? messageTypes.TEXT_WARNING
          : messageTypes.TEXT_NORMAL,
        sender: sender.STUDENT,
        remain: true
      });

      state.messages.push(studentMessage);
    });
  }
});

export const selectAllMessages = createSelector(
  state => state.learningOfflineTrial.messages,
  m => m
);

export default reducer;

export const { markDisplayed, continueWithDifferentLevel } = actions;
