import {
  createEntityAdapter,
  createSelector,
  createSlice
} from '@reduxjs/toolkit';

import { messageTypes, sender } from 'constant/learning-offline-trial';

import { fetchScenario, mergeWithDefaultValue } from './scenario-slice';
import { sentenceMade } from './sentence-maker-slice';

export const studentMessageAdapter = createEntityAdapter('studentMessages');
export const studentFilter = msg => msg.sender === sender.STUDENT;

const appendMessagesFromScnenario = (state, scenarioId) => {
  const scenario = state.scenarios.entities[scenarioId];
  const studentMessages = scenario.messages.filter(studentFilter);
  state.studentMessages.push(...studentMessages);
};

const { reducer, actions } = createSlice({
  name: 'studentMessages',
  reducers: {
    clearStudentMessages(state, action) {
      const isClearSoft = action.payload;
      if (isClearSoft) {
        state.studentMessages = state.studentMessages.filter(msg => msg.remain);
      } else {
        state.studentMessages = [];
      }
      // Need to re-append message from current step
      appendMessagesFromScnenario(state, state.scenarios.currentScenarioId);
    },
    addStudentMessage(state, action) {
      const studentMessage = mergeWithDefaultValue({
        content: action.payload,
        type: messageTypes.TEXT_STUDENT_ENTER,
        sender: sender.STUDENT,
        remain: true
      });

      state.studentMessages.push(studentMessage);
    },
    clearMessagesEnteredByStudent(state) {
      state.studentMessages = state.studentMessages.filter(
        msg => msg.type !== messageTypes.TEXT_STUDENT_ENTER
      );
    },
    skipVideo(state, action) {
      state.skipVideo =
        typeof action.payload !== 'undefined' ? action.payload : true;
    }
  },
  extraReducers: builder => {
    builder.addCase(fetchScenario, (state, action) => {
      appendMessagesFromScnenario(state, action.payload);
    });
    builder.addCase(sentenceMade, (state, action) => {
      const { sentence } = action.payload;
      const studentMessage = mergeWithDefaultValue({
        content: sentence,
        type: state.sentenceMaker.sentences.includes(sentence)
          ? messageTypes.TEXT_WARNING
          : messageTypes.TEXT_NORMAL,
        sender: sender.STUDENT,
        remain: true
      });

      state.studentMessages.push(studentMessage);
    });
  }
});

const allMessages = state => state.learningOfflineTrial.studentMessages;

export const selectAllStudentMessages = createSelector(
  allMessages,
  messages => messages
);

export const selectIsSkipVideo = state => state.learningOfflineTrial.skipVideo;

export default reducer;

export const {
  clearStudentMessages,
  addStudentMessage,
  clearMessagesEnteredByStudent,
  skipVideo
} = actions;
