import { _hsq } from './hubspot/tracking';

const isBrowser = () => typeof window !== 'undefined';
class UserTrackingService {
  constructor() {
    this.locations = [document.referrer];
    window['dolUserTracking'] = this;
  }

  trackNavigation(url) {
    // console.log('Adding URL', url);
    this.locations.push(url);
    setTimeout(() => {
      _hsq.push(['trackPageView']);
    }, 100);
  }

  getLastLocation() {
    return this.locations[this.locations.length - 2] === ''
      ? this.locations[this.locations.length - 1]
      : this.locations[this.locations.length - 2];
  }

  getCurrentLocation() {
    return this.locations[this.locations.length - 1] || window.location.href;
  }
}

export const UserTracking = isBrowser() ? new UserTrackingService() : null;
