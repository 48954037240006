import { createSelector, createSlice } from '@reduxjs/toolkit';
import { denormalize, normalize, schema } from 'normalizr';

import { ftQuestionTypes } from 'constant';

const UN_STARTED = -1;

const initialState = {
  linkId: null,
  verified: false,
  data: null,
  finalData: null,
  loaded: false,
  doTest: {
    isTimeout: false,
    isSubmitModalOpen: false,
    answerCount: 0,
    questionCount: 0,
    dataForSubmit: null,
    started: false,
    ended: false,
    totalSeconds: 0,
    current: {
      sectionIndex: UN_STARTED,
      questionIndex: UN_STARTED,
      subQuestionIndex: UN_STARTED
    }
  }
};

const { reducer, actions } = createSlice({
  name: 'entranceTest',
  initialState,
  reducers: {
    doneVerified(state, action) {
      state.linkId = action.payload;
      state.verified = true;
    },

    initData(state, action) {
      state.data = normalize(withMetadata(action.payload), entranceTestSchema);
      state.loaded = true;
    },
    startTest(state) {
      state.doTest = {
        ...state.doTest,
        started: true,
        current: {
          sectionIndex: 0,
          questionIndex: 0,
          subQuestionIndex: 0
        }
      };
    },
    endTest(state) {
      state.finalData = getFinalData(state);
      state.doTest = {
        ...state.doTest,
        ended: true
      };
    },
    timedOut(state) {
      state.doTest = {
        ...state.doTest,
        isTimeout: true
      };
      state.finalData = getFinalData(state);
    },
    prepareSubmitData(state) {
      const { entities, result } = state.data;
      const { questions, subQuestions, sections } = entities;

      let answerCount = 0;
      let questionCount = 0;
      Object.keys(subQuestions).map(id => {
        const item = subQuestions[id];
        if (
          item &&
          item.userAnswers &&
          item.userAnswers[0] &&
          (item.userAnswers[0] || '').length > 0
        ) {
          answerCount++;
        }
        questionCount++;
      });

      const prepareDataForSubmit = {
        id: result,
        sections: Object.keys(sections).map(id => {
          const sectionItem = sections[id];
          return {
            id: sectionItem.id,
            type: sectionItem.type,
            questions: sectionItem.questions.map(questionId => {
              return {
                id: questionId,
                subQuestions: questions[questionId].subQuestions.map(
                  subQuestionId => {
                    return {
                      id: subQuestionId,
                      userAnswers: subQuestions[subQuestionId].userAnswers
                    };
                  }
                )
              };
            })
          };
        })
      };

      state.doTest = {
        ...state.doTest,
        answerCount,
        questionCount,
        dataForSubmit: prepareDataForSubmit
      };
    },
    openSubmitModal(state) {
      state.doTest = {
        ...state.doTest,
        isSubmitModalOpen: true
      };
    },
    closeSubmitModal(state) {
      state.doTest = {
        ...state.doTest,
        isSubmitModalOpen: false
      };
    },
    calculateFinalData(state) {
      state.finalData = getFinalData(state);
    },
    answerQuestion(state, action) {
      const { questionId, selectedOption } = action.payload;
      const question = state.data.entities.questions[questionId];
      answer(question, selectedOption, state);
    },
    answerSubQuestion(state, action) {
      const { subQuestionId, selectedOption } = action.payload;
      const subQuestion = state.data.entities.subQuestions[subQuestionId];
      answer(subQuestion, selectedOption, state);
    },
    prevQuestion(state) {
      const { subQuestion, question, section } = getCurrentObjects(state);

      const prevQuestion = () => {
        if (question.isFirst) {
          if (!section.isFirst) {
            state.doTest.current.sectionIndex -= 1;
            const current = getCurrentObjects(state);
            state.doTest.current.questionIndex =
              current.section.questions.length - 1;
          }
        } else {
          state.doTest.current.questionIndex -= 1;
        }
      };

      if (
        !question.hasSubQuestions &&
        ftQuestionTypes.FILL_IN_THE_BLANK !== question.type
      ) {
        const { entities, result } = state.data;
        const { sectionIndex, questionIndex } = state.doTest.current;

        const sectionId = entities.entranceTests[result].sections[sectionIndex];
        const section = entities.sections[sectionId];

        const previousQuestionId = section.questions[questionIndex - 1];
        const previousQuestion = entities.questions[previousQuestionId];
        if (
          previousQuestion &&
          previousQuestion.type === ftQuestionTypes.FILL_IN_THE_BLANK
        ) {
          state.doTest.current.questionIndex -= 1;
          state.doTest.current.subQuestionIndex = 0;

          return;
        }
      }

      if (question.hasSubQuestions && isCanBack(state)) {
        if (subQuestion.isFirst) {
          // reset index to last subQuestion of previous question
          prevQuestion();
          const current = getCurrentObjects(state);
          state.doTest.current.subQuestionIndex =
            current.question.subQuestions.length - 1;
        } else {
          state.doTest.current.subQuestionIndex -= 1;
        }
      } else {
        prevQuestion();
      }
    },
    nextQuestion(state) {
      nextQuestion(state);
    }
  }
});

export default reducer;

export { actions };

//           _           _
//  ___  ___| | ___  ___| |_ ___  _ __ ___
// / __|/ _ \ |/ _ \/ __| __/ _ \| '__/ __|
// \__ \  __/ |  __/ (__| || (_) | |  \__ \
// |___/\___|_|\___|\___|\__\___/|_|  |___/

const entranceTestStoreSelector = state => state.entranceTest;
const dataSelector = state => state.entranceTest.data;
const doTestSelector = state => state.entranceTest.doTest;

export const selectCurrent = createSelector(
  entranceTestStoreSelector,
  store => {
    if (!store.doTest.started)
      return {
        section: {},
        question: {},
        subQuestion: {}
      };

    return getCurrentObjects(store);
  }
);

export const selectCurrentIndies = createSelector(
  doTestSelector,
  doTest => doTest.current
);

export const selectEntranceTestData = createSelector(
  dataSelector,
  data => data && data.entities.entranceTests[data.result]
);

export const selectTotalQuestions = createSelector(
  selectEntranceTestData,
  test => (test ? test.total : 0)
);

export const selectLoadedStatus = createSelector(
  entranceTestStoreSelector,
  store => store.loaded
);

export const selectLeafRank = createSelector(
  entranceTestStoreSelector,
  doTestSelector,
  selectCurrent,
  (store, doTest, current) => {
    if (!store.loaded || !doTest.started) return 0;

    const { section, question, subQuestion } = current;

    return (
      section.rank +
      question.rank +
      (question.hasSubQuestions ? subQuestion.rank : 0)
    );
  }
);

export const selectSectionNames = createSelector(
  entranceTestStoreSelector,
  store =>
    store.data
      ? Object.values(store.data.entities.sections).map(section => section.name)
      : []
);

export const selectTotalTime = createSelector(
  selectEntranceTestData,
  entranceTest => (entranceTest ? entranceTest.time : 0)
);

export const selectAllSubQuestions = createSelector(
  dataSelector,
  data => data.entities.subQuestions
);

export const selectIsTimeout = createSelector(
  doTestSelector,
  doTest => doTest.isTimeout
);

export const selectIsTestEnd = createSelector(
  doTestSelector,
  doTest => doTest.ended
);

export const selectIsVerified = createSelector(
  entranceTestStoreSelector,
  entranceTest => entranceTest.verified
);

export const selectSubmitModalOpen = createSelector(
  doTestSelector,
  doTest => doTest.isSubmitModalOpen
);

export const selectQuestionCount = createSelector(
  doTestSelector,
  doTest => doTest.questionCount
);

export const selectAnswerCount = createSelector(
  doTestSelector,
  doTest => doTest.answerCount
);

export const selectDataForSubmit = createSelector(
  doTestSelector,
  doTest => doTest.dataForSubmit
);

export const selectLinkId = createSelector(
  entranceTestStoreSelector,
  entranceTest => entranceTest.linkId
);

//  _          _                    __                  _   _
// | |__   ___| |_ __   ___ _ __   / _|_   _ _ __   ___| |_(_) ___  _ __  ___
// | '_ \ / _ \ | '_ \ / _ \ '__| | |_| | | | '_ \ / __| __| |/ _ \| '_ \/ __|
// | | | |  __/ | |_) |  __/ |    |  _| |_| | | | | (__| |_| | (_) | | | \__ \
// |_| |_|\___|_| .__/ \___|_|    |_|  \__,_|_| |_|\___|\__|_|\___/|_| |_|___/
//              |_|

const subQuestionSchema = new schema.Entity('subQuestions');
const questionSchema = new schema.Entity('questions', {
  subQuestions: [subQuestionSchema]
});
const sectionSchema = new schema.Entity('sections', {
  questions: [questionSchema]
});
export const entranceTestSchema = new schema.Entity('entranceTests', {
  sections: [sectionSchema]
});

const attachPositionInfo = (obj, idx, arr) => {
  obj.isFirst = idx === 0;
  obj.isLast = idx === arr.length - 1;
  obj.isBetween = !(obj.isFirst || obj.isLast);
};

/**
 * - add rank/isLast/isFirst/isBetween for subQuestions, questions, sections
 * - add userAnswers field
 * - add hasSubQuestions inside every question
 * - add totalQuestions for entranceTest/sections/questions
 * - add hasMultipleCorrectOptions for question/subQuestion
 * - add total time for entranceTest
 */
const withMetadata = entranceTest => {
  let previousSectionRank = 0;
  entranceTest.total = 0;
  entranceTest.time = 0;

  entranceTest.sections.forEach((section, sectionIdx, sections) => {
    section.total = 0;
    entranceTest.time += section.time;
    attachPositionInfo(section, sectionIdx, sections);
    let previousQuestionRank = 0;

    section.questions.forEach((question, questionIdx, questions) => {
      attachPositionInfo(question, questionIdx, questions);
      question.userAnswers = [];
      question.hasSubQuestions = question.subQuestions.length > 0;
      question.hasMultipleCorrectOptions = question.correctOptions.length > 1;
      let previousSubQuestionRank = 0;
      question.total = 0;

      question.subQuestions.forEach((subQuestion, idx, subQuestions) => {
        attachPositionInfo(subQuestion, idx, subQuestions);
        subQuestion.userAnswers = [];
        subQuestion.hasMultipleCorrectOptions =
          subQuestion.correctOptions.length > 1;
        subQuestion.rank = 0;
        // indicate no correct options, count it self
        previousSubQuestionRank += subQuestion.correctOptions.length || 1;
        subQuestion.rank = previousSubQuestionRank;
      });

      question.rank = previousQuestionRank;
      previousQuestionRank += previousSubQuestionRank;
      question.total = previousSubQuestionRank;
    });

    section.rank = 0;
    if (sectionIdx > 0) {
      section.rank = previousSectionRank;
    }

    previousSectionRank += previousQuestionRank;
    section.total = previousQuestionRank;
    entranceTest.total += section.total;
  });

  return entranceTest;
};

const getCurrentObjects = store => {
  const { entities, result } = store.data;
  const { sectionIndex, questionIndex, subQuestionIndex } =
    store.doTest.current;

  const sectionId = entities.entranceTests[result].sections[sectionIndex];
  const section = entities.sections[sectionId];

  const questionId = section.questions[questionIndex];
  const question = entities.questions[questionId];

  const subQuestionId = question.subQuestions[subQuestionIndex];
  const subQuestion = entities.subQuestions[subQuestionId];

  return {
    section,
    question,
    subQuestion
  };
};

const isCanBack = state => {
  const { sectionIndex, questionIndex, subQuestionIndex } =
    state.doTest.current;
  if (sectionIndex === UN_STARTED) return false;

  return sectionIndex > 0 || questionIndex > 0 || subQuestionIndex > 0;
};

const getFinalData = state => {
  const { entities, result: entranceTestId } = state.data;
  const entranceTest = entities.entranceTests[entranceTestId];
  const denormalized = denormalize(entranceTest, entranceTestSchema, entities);

  const finalData = {
    id: denormalized.id,
    sections: denormalized.sections.map(section => ({
      id: section.id,
      type: section.type,
      questions: section.questions.map(question => ({
        id: question.id,
        type: question.type,
        userAnswers: question.userAnswers,
        subQuestions: question.subQuestions.map(subQuestion => ({
          id: subQuestion.id,
          type: subQuestion.type,
          userAnswers: subQuestion.userAnswers
        }))
      }))
    }))
  };

  return finalData;
};

const getCurrentStatus = store => {
  const { section, question, subQuestion } = getCurrentObjects(store);

  let isLast = section.isLast && question.isLast;
  let isSelectAllPosibileOptions = false;
  let $question = question;

  if (question.hasSubQuestions) {
    $question = subQuestion;
  }

  isLast = isLast && $question.isLast;
  isSelectAllPosibileOptions =
    subQuestion.userAnswers.length === subQuestion.correctOptions.length;

  return {
    isLast,
    isSelectAllPosibileOptions
  };
};

const nextQuestion = state => {
  const { subQuestion, question, section } = getCurrentObjects(state);

  const $nextQuestion = () => {
    if (question.isLast) {
      state.doTest.current.questionIndex = 0;
      if (!section.isLast) {
        state.doTest.current.sectionIndex += 1;
      }
    } else {
      state.doTest.current.questionIndex += 1;
    }
  };

  if (question.type === ftQuestionTypes.FILL_IN_THE_BLANK) {
    state.doTest.current.subQuestionIndex = 0;
    $nextQuestion();

    return;
  }

  if (question.hasSubQuestions) {
    if (subQuestion.isLast) {
      // reset index to 0
      state.doTest.current.subQuestionIndex = 0;
      $nextQuestion();
    } else {
      state.doTest.current.subQuestionIndex += 1;
    }
  } else {
    $nextQuestion();
  }
};

const answerSelectionQuestionType = ($question, selectedOption) => {
  // Support untick
  if ($question.userAnswers.includes(selectedOption)) {
    $question.userAnswers = $question.userAnswers.filter(
      answer => answer !== selectedOption
    );
  } else if ($question.userAnswers.length > 0) {
    // already has answer
    $question.userAnswers.pop();
    $question.userAnswers.unshift(selectedOption);
  } else {
    // has no answer
    $question.userAnswers[0] = selectedOption;
  }
};

const answerFillInTheBlankQuestionType = ($question, answers) => {
  $question.userAnswers = answers;
};

const answer = ($question, selectedOption, state) => {
  switch ($question.type) {
    case ftQuestionTypes.FILL_IN_THE_BLANK:
      answerFillInTheBlankQuestionType($question, selectedOption);
      break;
    default:
      answerSelectionQuestionType($question, selectedOption);
      break;
  }

  // Support auto next question when complete answer
  // But not for Fill in the blank question type :))
  if ($question.type !== ftQuestionTypes.FILL_IN_THE_BLANK) {
    const status = getCurrentStatus(state);
    if (!status.isLast && status.isSelectAllPosibileOptions) {
      nextQuestion(state);
    }
  }
};
